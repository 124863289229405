


















































































































import API from '@/plugins/axios';
import Vue from 'vue';
export default Vue.extend({
  data: () => ({
    hoverId: 0,
    page: 1,
    perPage: 25,
    count: -1,
    data: [] as { id: number }[],
    autocompleteValue: '',
    search: '',
    loading: false,
    showDialog: false,
    unitToDelete: {} as unknown,
    items: [] as unknown[],
    // eslint-disable-next-line no-undef
    timer: 0 as unknown as NodeJS.Timeout,
  }),
  watch: {
    async search(value: string) {
      clearTimeout(this.timer);
      if (value && value.trim() && value.trim().length >= 3) {
        this.loading = true;
        this.timer = setTimeout(async () => {
          try {
            const { data } = await API.post(`/administration/search/`, {
              search_term: value,
            });
            this.items = data.results.map((item: { name: string; id: number }) => ({
              text: item.name,
              value: item.id,
            }));
          } catch {
            this.items = [];
          }
          this.loading = false;
        }, 500);
      } else {
        this.items = [];
      }
    },
  },
  methods: {
    async load() {
      const { data } = await API.get(`/administration/units/?page=${this.page}`);
      this.data = [...this.data, ...data.results];
      this.count = data.count;
      this.page++;
    },
    async deleteUnit(unit: unknown) {
      this.showDialog = true;
      this.unitToDelete = unit;
    },
    async deleteSelected() {
      const { id } = this.unitToDelete as { id: number };
      const { status } = await API.delete(`/administration/units/${id}/`);
      if (status == 200) {
        this.data = [...this.data.filter((unit) => unit.id !== id)];
        this.count--;
      }
    },
    editUnit(id: number) {
      this.$router.push(`/admin/unit/${id}/edit`);
    },
    openItem(id: string) {
      this.$router.push(`/admin/unit/${id}`);
    },
  },
  async mounted() {
    await this.load();
  },
});
