var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticStyle:{"width":"300px"}},[_c('v-combobox',{ref:"autocomplete",staticClass:"mt-8",attrs:{"search-input":_vm.search,"loading":_vm.loading,"filled":"","dense":"","placeholder":"Search","prepend-inner-icon":"mdi-magnify","items":_vm.items,"no-filter":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.search && _vm.search.trim().length >= 3 && !_vm.items.length && !_vm.loading)?_c('v-list-item',{attrs:{"disabled":""}},[_vm._v(" No items match your search. ")]):_vm._e()]},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{staticClass:"progressBar",attrs:{"indeterminate":"","color":"primary","absolute":"","height":"2px"}})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openItem(item.value)}}},'v-list-item',attrs,false),on),[_c('span',{staticClass:"ellipsis"},[_vm._v(" "+_vm._s(item.text)+" ")])])]}}]),model:{value:(_vm.autocompleteValue),callback:function ($$v) {_vm.autocompleteValue=$$v},expression:"autocompleteValue"}})],1),_c('v-row',{staticClass:"mb-4 mx-4"},[_c('h2',{staticClass:"mr-4"},[_vm._v("Skills Kits")]),_c('v-btn',{staticClass:"buttonColor",attrs:{"outlined":"","to":"/admin/unit/new"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" ADD NEW ")],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-list',{attrs:{"color":"inherit"}},_vm._l((_vm.data),function(item){return _c('v-list-item',{key:item.id,class:{
          active: _vm.hoverId == item.id,
          backgroundColor: true,
          'pl-2': true,
        },attrs:{"two-line":"","to":("/admin/unit/" + (item.id))},on:{"mouseenter":function($event){_vm.hoverId = item.id},"mouseleave":function($event){_vm.hoverId = false}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',{staticClass:"maxWidth"},[_vm._v(_vm._s(item.description))])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.editUnit(item.id)}}},[(_vm.hoverId == item.id)?_c('v-icon',[_vm._v("mdi-pencil")]):_vm._e()],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteUnit(item)}}},[(_vm.hoverId == item.id)?_c('v-icon',[_vm._v("mdi-delete")]):_vm._e()],1)],1)}),1),_c('v-row',{staticClass:"mt-4 mb-8",attrs:{"justify":"center"}},[(_vm.count > _vm.data.length)?_c('v-btn',{staticClass:"buttonColor",attrs:{"rounded":"","width":"168","depressed":""},on:{"click":_vm.load}},[_vm._v(" Load more "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete Skill Kit?")]),_c('v-card-text',[_vm._v(" Are you sure you want to delete skills kit "),_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v(_vm._s(_vm.unitToDelete.name))]),_vm._v(" ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"buttonColor",attrs:{"outlined":""},on:{"click":function($event){_vm.showDialog = false;
            _vm.unitToDelete = {};}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"red"},on:{"click":function($event){_vm.showDialog = false;
            _vm.deleteSelected();
            _vm.unitToDelete = {};}}},[_vm._v(" Delete ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }